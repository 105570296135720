import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic from '../assets/images/vue-theme-argon-design-page.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Vue Theme - Argon Design</title>
            <meta name="description" content="Vue theme crafted by Creative-Tim. Enhanced by @AppSeed.us" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Vue Theme - Argon Design</h1>
                    </header>
                    <span className="image main"><img src={pic} alt="Vue Theme Argon Design - Crafted by Creative-Tim" /></span>
                    <p>
                        <h2>What is Vue.js?</h2>
                        For newcommers, Vue  is a progressive framework for building user interfaces. Unlike other monolithic frameworks, Vue is designed from the ground up to be incrementally adoptable.
                        Vue is perfectly capable of powering sophisticated Single-Page Applications when used in combination with modern tooling.
                    </p>
                    <p>
                        <h2>Resources</h2>
                        Live <a target="_blank" without rel="noopener noreferrer" href="https://vue-themes-argon-design.appseed.us">Demo</a>, 
                        Github <a target="_blank" without rel="noopener noreferrer" href="https://github.com/vue-theme/argon-design">Sources</a> 
                    </p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic